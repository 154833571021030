.body-text {
  font-size: 1.3rem;
}

.content,
.body-text {
  padding-bottom: 2rem;
}

@media screen and (max-width: 768px) {
  #main-menu {
    padding-top: 50%;
  }
}
